import { render, staticRenderFns } from "./WorkflowConfig.vue?vue&type=template&id=e514156a&scoped=true&"
import script from "./WorkflowConfig.vue?vue&type=script&lang=js&"
export * from "./WorkflowConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e514156a",
  null
  
)

export default component.exports